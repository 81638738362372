/* NewMissionForm.css */
body {
  margin: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #53bccb;
  padding: 20px;
}

.form {
  border: 1px solid #000000;
  padding: 20px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 10px;
}

select {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #000000;
  border-radius: 4px;
}

input[type="number"] {
  width: 97.86%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 1px solid #000000;
  border-radius: 4px;
}

.form-group label,
.form-group select,
.form-group input[type="number"] {
  margin: 0;
}

button {
  background-color: #53bccb;
  color: #fff;
  border: none;
  padding: 12px 24px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s ease;
  margin-top: 16px;
  font-size: 16px;
  font-weight: bold;
}

button:hover {
  background-color: #77bfca;
}

.checkBox {
  padding-bottom: 10px;
}

.formValidatedPopUp {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 2px solid #53bccb;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 1000;
  text-align: center;
  font-size: 1.2em;
  color: #333;
}

.checkBox {
  display: flex;
  flex-direction: column;
}

.checkBox p {
  margin-bottom: 10px;
}

.checkBox label {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.checkBox .checkbox-group {
  display: flex;
  flex-wrap: wrap;
}
